<script setup lang="ts">
type Variant = "glass" | "inherit" | "transparent" | "table" | "outline";
type Size = "base" | "lg";

const {
  icon = "home",
  variant = "inherit",
  size = "base",
  number = 0,
  tooltip = null,
} = defineProps<{
  icon?: string;
  variant?: string;
  size?: string;
  number?: number;
  tooltip?: string | null;
}>();

const variants: Record<Variant, { style: string }> = {
  glass: {
    style:
      "bg-gray-100/80 text-gray-700 hover:bg-gray-200/50 hover:text-gray-800 dark:hover:bg-primary-500/50",
  },
  inherit: {
    style: "hover:bg-gray-200/20",
  },
  transparent: {
    style: "bg-transparent text-gray-200 hover:bg-gray-200/50 dark:text-white",
  },
  table: {
    style:
      "bg-gray-100 dark:bg-gray-800 text-gray-900 dark:text-gray-400 hover:bg-gray-500 hover:text-gray-100 dark:text-white dark:hover:bg-gray-500",
  },
  outline: {
    style:
      "bg-white text-gray-800 dark:text-white ring-1 hover:bg-gray-100  dark:text-gray-400 dark:bg-gray-800 dark:hover:bg-gray-700 disabled:bg-transparent disabled:text-gray-400 disabled:ring-gray-400",
  },
};

const sizes: Record<Size, string> = {
  base: "h-5 w-5 md:h-[38px] md:w-[38px] text-sm",
  lg: "h-7 w-7 md:h-[44px] md:w-[44px] text-base",
};
</script>

<template>
  <VTooltip>
    <div :class="variant === 'outline' && 'bg-gradient-1 rounded-full p-[1px]'">
      <div
        class="relative flex cursor-pointer items-center justify-center rounded-full transition"
        :class="[variants[variant as Variant].style, sizes[size as Size]]"
      >
        <i
          :class="`ri-fw ri-${icon} ${
            variant === 'outline' ? 'md:text-2xl' : 'text-sm md:text-base'
          }`"
        />
        <div
          v-if="number > 0"
          class="text-gray- absolute bottom-0 -right-1 rounded-full border border-white bg-primary-500 px-[4px] md:px-[6px] py-[4px] text-[8px] md:text-[10px] font-bold leading-[4px] md:leading-[9px] text-white"
        >
          {{ number }}
        </div>
      </div>
    </div>
    <template #popper>
      <div
        v-if="tooltip"
        class="-mb-[6px] w-fit animate-fadeIn_fast rounded-lg bg-gray-900 px-2 py-1 text-[12px] font-medium text-white transition-all"
      >
        <div>{{ tooltip }}</div>
      </div>
    </template>
  </VTooltip>
</template>
